<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Composition cellule client" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="8" lg="9" md="10" sm="11" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <!-- le détail du service -->
        <DetailViewBoxComponent title="Détail d'une composition de cellule client">
          <template v-slot:items>
            <InputFieldComponent label="Produit" :value="productName" />

            <!-- Les périmètres -->
            <InputFieldComponent label="Périmètre(s)" alignLabel="start" :divider="false">
              <template v-slot:value>
                <div v-for="(item, i) in items" :key="i">
                  <v-row no-gutters align="center" justify="space-between" class="mb-3">
                    <v-col cols="7">
                      <div>{{ item.perimeterName }}</div>
                    </v-col>
                    <v-col cols="5">
                      <div>{{ item.value }}</div>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </InputFieldComponent>

          </template>
        </DetailViewBoxComponent>
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar>

  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { CustomerCellsService } from "@/service/conf/transmission/customer_cells_service.js";
import { AvecProductsService } from "@/service/conf/avec_products_service.js";
import { PerimetersService } from "@/service/conf/transmission/perimeters_service.js";


import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import * as logger from "@/tools/logger.js";


export default {
  name: "DetailsCustomerCells",
  components: { Workflow, TitleAndReturnComponent, DetailViewBoxComponent, InputFieldComponent },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin,],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service des entités */
      service: null,

      /**l'identifiant de l'entité à visualiser. */
      entityId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /** le produit  */
      productName: null,
      /** les périmètres */
      items: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.items = [];

        // Récupération de la cellule client
        let entity = await this.service.getById(this.entityId);
        logger.debug("entity", entity)

        // Récupération du produit de la cellule client
        let product = await this.serviceProducts.getById(entity.avecProduct);
        this.productName = product.digitalName;

        // Récupération de tous les périmètres
        let perimeters = await this.servicePerimeters.getAll();

        // Parcours des périmètres de la cellule client
        for(let item of entity.items) {
          // Récupération du périmètre en cours
          let foundPeri = perimeters.find((p) => p.id == item.perimeter);

          item.perimeterName = foundPeri.label;

          this.items.push(item);
        }
        
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {
    // Instanciation de la classe service
    this.service = new CustomerCellsService(this.$api.getCustomerCellsApi());
    this.serviceProducts = new AvecProductsService(this.$api.getTransmissionsAvecProductsApi());
    this.servicePerimeters = new PerimetersService(this.$api.getPerimetersApi());


    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>